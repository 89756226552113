.TimelineTextInstance {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  display: flex;
  background-color: rgba(255, 215, 0, 0.75);
  border-radius: .125rem;
  border: 2px solid rgba(255, 255, 255, 1);
  cursor: -webkit-grab;
  box-sizing: border-box;
  transition: 0.2s all ease;

  &:hover,
  &:active {
    .TimelineTextInstance__resizeHandle {
      opacity: 1;
    }
  }

  &:active {
    cursor: -webkit-grabbing;
  }
}

.TimelineTextInstance--selected {
  border-color: var(--color1);
  background: rgba(24, 227, 255, 0.5);
}

@keyframes upAndDown {
  0% {
    top: -52px;
  }

  66% {
    top: -56px;
  }

  100% {
    top: -52px;
  }
}

.TimelineTextInstance--swapLeft {
  &:before {
    content: "\2193";
    position: absolute;
    top: -52px;
    left: -17px;
    color: white;
    font-size: 36px;
    animation: upAndDown 1s infinite;
  }

  &:hover,
  &:active {
    .TimelineTextInstance__resizeHandle {
      opacity: 0;
    }
  }
}

.TimelineTextInstance--swapRight {
  &:after {
    content: "\2193";
    position: absolute;
    top: -52px;
    right: -17px;
    color: white;
    font-size: 36px;
    animation: upAndDown 1s infinite;
  }

  &:hover,
  &:active {
    .TimelineTextInstance__resizeHandle {
      opacity: 0;
    }
  }
}

.TimelineTextInstance__resizeHandle {
  position: absolute;
  width: 15%;
  height: 18px;
  max-width: 15px;
  min-width: 5px;
  top: -2px;
  opacity: 0;
  z-index: 2;
  cursor: col-resize;
  background: #18e3ff;
  box-sizing: border-box;

  &.left {
    left: -2px;
  }

  &.right {
    right: -2px;
  }
}

.TimelineTextInstance--canDelete {
  background: rgba(255, 24, 24, 0.5);
  border-color: #ff1717;
}

.TimelineTextInstance--isEmpty {
  background: rgb(24, 227, 255);
  border-color: var(--color4);

  &:hover,
  &:active {
    .TimelineTextInstance__resizeHandle {
      opacity: 0;
      cursor: default;
    }
  }
}

.TimelineTextInstance__snap--indicator {
  position: absolute;
  right: -2px;
  bottom: -50px;
  height: 125px;
  width: 2px;
  background-color: white;
}

.TimelineTextInstance--isOver {
  border: 12px solid var(--color1);
  background-color: rgba(255, 215, 0, 0.75);
}

.TimelineTextInstance__Lock {
  position: absolute;
  left: 50%;
  bottom: -10px;
  left: -2px;
  display: block;
  bottom: -5px;
  height: 20px;
  width: 1px;
  border-left: 2px solid white;
  background-color: white;
  visibility: visible;

  &.__unlocked {
    visibility: hidden;
  }
}