.EmptyComposition {
  width: 100%;
  height: 47px;
  background-color: #2d2d2d;
}

.EmptyComposition--hovered {
  box-shadow: 0 0 0 0.125rem var(--color1);
}

.EmptyComposition--highlighted {
  background-color: rgba(105, 100, 115, 1);
}