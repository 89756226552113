.TimelineVideoInstance {
  width: 100px;
  height: 100px;
  display: inline-block;
  border-radius: .125rem;
  border: 2px solid rgba(255, 255, 255, 1);
  cursor: -webkit-grab;

  // drag handles
  &:hover,
  &:active {

    span {
      div {
        background-color: var(--color1);
      }
    }
  }

  &:active {
    cursor: -webkit-grabbing;
  }
}

.TimelineVideoInstance--placeholder {
  background-color: rgba(0, 0, 0, 0) !important;
  color: rgba(0, 0, 0, 0);
  border-color: #2ebf91;

  .InstanceBackground {
    background-image: none !important;
  }
}

.TimelineVideoInstance--replace {
  border-color: #2ebf91;
}

.TimelineVideoInstance--selected {
  // box-shadow: 1px 0px 18px var(--color1);
  border-color: var(--color1);
}

.TimelineVideoInstance--delete {
  opacity: .3;
}

.TimelineVideoInstance.TimelineVideoInstance--selected .InstanceBackground {
  filter: saturate(1);
}