.timeline {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
}

.timelineCurtain {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
}

// @keyframes expand {
//   0% {flex-grow: 1;}
//   100% {flex-grow: 4;}
// } 

.poolContainer {
  display: flex;
  width: 100%;
  flex-grow: 1;
  background-color: var(--color5);
  margin: 2px 0px;

  .instanceIcon {
    pointer-events: none;
    height: 100%;

    svg {
      display: none;
    }
  }

  &--expanded {
    // animation-name: expand;
    // animation-duration: .5s;
    // animation-timing-function: ease-out;
    flex-grow: 6;
    background-color: var(--color5);

    .instanceIcon {
      position: absolute;
      pointer-events: none;
      height: 100%;
      left: 20px;
      transition: opacity .5s;

      svg {
        display: flex;
        position: relative;
        top: -5px;
        left: -20px;
        transform: scale(0.5);
        fill: white;
      }
    }
  }
}

.VideoControls {
  svg {
    transform: scale(.8);
  }
}

.toBegin {
  svg {
    transform: scale(.45);
  }

}

.toEnd {
  svg {
    transform: scale(.45) rotate(180deg);
  }
}

.pool-icon {
  width: 5%;
  align-self: center;
  max-height: 100%;

  .icon {
    height: 100%;
    margin: auto;
  }

  svg {
    transform: scale(.8);
    fill: var(--color2);
  }
}

.scissors {
  background-color: var(--color5);
  max-height: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    transform: scale(.6);
    top: 13px;
    fill: none;
    stroke: var(--color2)
  }
}

.pool-wrapper {
  width: 95%;
  display: flex;
  flex-direction: column;
}

.ticker {
  height: 10px;

  &:hover {
    cursor: pointer;
  }
}

.highlighted {
  background-color: #9999ff !important;
  box-shadow: inset 0px 2px 3px white !important;
  background-blend-mode: luminosity;
}

.pool {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px;
}

.track {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 1px;
  background-color: var(--color4);
  margin: 1px;

  &:hover {
    background-color: var(--color3);
  }
}

.onTrack {
  background-color: #9999ff;
}

.transition {
  transition-property: left;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}

.instance {
  position: absolute;
  width: 33%;
  height: 100%;
  min-height: 9px;
  border-radius: .2em;
  padding: 3px 1px;

  &:hover {
    cursor: grab;
  }

  .dragHandles {
    box-shadow: inset .0em -.1em 4px rgba(0, 0, 0, .1);
    height: 100%;
    width: 100%;
    display: flex;
    border-radius: .2em;
    background-size: cover;
    background-position: center center;

    .dragHandle {
      height: 100%;
    }
  }

  .shape {
    background-color: #e5829d;
  }

  .text {
    background-color: #eac56b;
  }

  .layer {
    background-color: #81cfca;
  }

  .video {
    background-color: #81cfca;
  }

  .image {
    background-color: #81cfca;
  }

  .logo {
    background-color: #81cfca;
  }

  .resizeHandler {
    background-color: rgba(255, 255, 255, 0.4);
    flex-grow: 1;
    height: 100%;
    opacity: 0;
    min-width: 2px;
    max-width: 50px;
  }

  .outPoint {
    border-radius: 0em .2em .2em 0em;

    &:hover {
      opacity: 1;
      cursor: ew-resize;
    }

    transition: opacity .3s;
  }

  .inPoint {
    border-radius: .2em .0em .0em .2em;

    &:hover {
      cursor: ew-resize;
      opacity: 1;
    }
  }
}