.Timeline {
  width: 100%;
  display: flex;
  position: relative;
  padding-right: 6rem;
  box-shadow: -8px 0px 4px -1px  rgba(0, 0, 0, .1);
 .TimeLine__icons {
   z-index: 100;
   justify-content: center;
   margin: auto;
   align-items: auto;
   display: flex;
   width: 10rem;
   padding-top: 3rem;
   .TimeLine__icons--play{
     display: inline-block;
     border-radius: 50%;
     width: 60px;
     height: 60px;
     line-height: 0;
     border: 1px solid grey;
     background-color: rgba(255, 255, 255, 0.05);
   }
  }
}
.TimeLine__wrapper {
  width: 100%;
  user-select: none;
  padding: 1rem;
  overflow: hidden;
}

.Timeline__row{
  padding: 2.3px 0;
  display: flex;
}

.Timeline__row--textrow {
  height: 18px;
  position: relative;
}

.Timeline__row--resize-left {
  justify-content: flex-end;
}

.Timeline__row--resize-right {
  justify-content: flex-start;
}

.Timeline__cell {
  display: block;
  &:last-child {
    padding-right: 0;
  }

  &:first-child {
    padding-left: 0;
  }

  &--fullwidth {
    width: 100%;
    padding-right: 2px;
  }
  .cell_container {
    position: relative;
  }
}
