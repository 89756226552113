.TimelineImageInstance {
  display: inline-block;
  border-radius: .125rem;
  border: 2px solid #fff;

  // drag handles
  &:hover {
    span {
      div {
        background-color: var(--color1);
      }
    }
  }
}

.TimelineImageInstance__wrapper {
  height: 100%;
}

.TimelineImageInstance--placeholder {
  background-color: rgba(0, 0, 0, 0) !important;
  color: rgba(0, 0, 0, 0);
  border-color: #2ebf91;

  .InstanceBackground {
    background-image: none !important;
  }
}

.TimelineImageInstance--replace {
  border-color: #2ebf91;
}

.TimelineImageInstance--selected {
  border-color: var(--color1);
  box-shadow: 1px 0px 18px var(--color1);

  .InstanceBackground {
    filter: saturate(1);
  }
}