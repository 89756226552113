.Workspace {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  .Toolbar {
    display: flex;
    position: relative;
    height: 52px;
    min-width: 42rem;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    // box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    background-color: var(--color5);

    h2 {
      font-size: 14px;
      font-weight: 500;
      margin-top: 10px;
    }

    .ToolbarGroup {
      display: flex;
      align-items: center;

      div {
        font-size: 11px;
      }

      span {
        display: flex;
      }

      .active {
        background-color: #c62e2d;

        span {
          color: white;
          font-size: 11px;
        }



        svg {
          fill: white;
        }
      }

      // border: 1px solid #f3f3f3;
      // border-width: 0px 1px 0px 0px;
      .ToolbarThumbnail {
        height: 52px;
        display: flex;
          align-items: center;
          justify-content: center;
      }

      .ToolbarItem {
        &.dots {
          min-width: 32px;
        }

        &.duration {
          display: flex;
          gap: 0.4rem;
          justify-items: center;
          align-items: center;
          height: 32px;
          border: none;
          padding: 0 10px;
          width: auto;
          margin-right: 2rem;
          transition: background ease-in-out .2s;

          &:hover {
            background: var(--color4);
          }

          .inputNumber {
            input {
              top: 0;
              width: 25px;
              font-size: 12px;
              font-weight: 400;
              color: var(--color2);
              border-radius: 0.2em;
              text-align: center;
              margin: 0;

              &:hover {
                background-color: var(--color4);
              }

              &:focus {
                font-weight: 800;
                background-color: var(--color4)
              }
            }

            span {
              // align-self: center;
              height: 100%;
              font-size: 12px;
              padding-top: 4px;
            }
          }
        }

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}

.ToolbarContent {
  width: 100%;
  background: white;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.ToolbarTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;
}

.MuiIconButton-label {
  span {
    color: white !important;
  }
}

.Type {
  justify-content: center;
  max-width: 80px;
  padding: 1px !important;

  svg {
    fill: white;
  }
}

.CopyAndDelete {
  justify-content: flex-end;
  border: none;
}

.TrimmerGroup {
  flex-grow: 10;
  border: none;
  width: 100%;
}

.ToolbarItem.trimmer {
  width: 100%;
}

.ToolbarItem {
  display: flex;
  margin: 0.25rem;
  padding: 0.5rem;
  min-width: 36px;
  font-size: 0.875rem;
  color: var(--color2);
  border-radius: 0.25rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: var(--color2);
  background-color: var(--color4);
  cursor: pointer;
  height: 32px;

  .videoRow-overlay {
    width: 100%;
  }

  .Button {
    padding: 0 !important;
    max-height: 1rem;
    color: var(--color2);
    font-weight: 400;
  }

  svg {
    width: 1rem;
    height: 1rem;
    fill: var(--color2);
  }

  &.delete {
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &--Apply {
    font-size: 14px;
    width: 90px;

    button {
      position: relative;
      top: 0px;
    }
  }

  &--dropDown {
    margin: 0px 0px;
    font-size: 14px;
    width: 100px;
  }

  &--lineHeight {
    width: 200px;
    padding: 15px;
  }
}

.Type {
  svg {
    fill: white;
  }
}

.Toolbar__dropDown {
  display: flex;
  flex-direction: column;
  color: #c3ced7;
  background: #232527;
  position: absolute;
  top: 52px;
  padding: 12px;
  margin-left: -40px;
  z-index: 100;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.row {
  flex-direction: row !important;
}

.Toolbar__right {
  display: flex;
  position: absolute;
  height: 100%;
  right: 0;
}