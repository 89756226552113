@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;700&display=swap');

:root {
    --color1: #c62e2d;
    --color2: #c3ced7;
    --color3: #454a4e;
    --color4: #2f3336;
    --color5: #232527;
    --color6: #c62e2d;
    --color7: #ffffff;
}

.undo-redo svg {
    stroke: none
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
input,
textarea,
button,
div,
select,
a {
    font-family: "Quicksand", sans-serif
}


input,
textarea {
    -webkit-touch-callout: auto;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;

}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
input,
textarea,
span:not(.previewContainer span) {
    font-family: "Quicksand", arial;
    font-size: 11px;
}

button,
div,
input,
label,
option,
p,
span {
    color: var(--color2)
}

nav.sumo-navbar li span {
    font-size: 14px;
}

nav.sumo-navbar {
    box-sizing: content-box !important;
}

/* TEXTS */

.sumo-text-small {
    font-size: 11px;
}

.sumo-text-default {
    font-size: 14px;
}

.sumo-text-regular {
    font-weight: 500;
}

.sumo-text-bold {
    font-weight: bold;
}

.sumo-blue-title {
    font-size: 11px;
    text-transform: uppercase;
    color: var(--color1);
}

.sumo-paint-text {
    color: var(--color6);
}

.sumo-tunes-text {
    color: #e5ab42;
}

.sumo-3d-text {
    color: var(--color1);
}

.sumo-code-text {
    color: #22cc71;
}

.sumo-photo-text {
    color: #30b7a7;
}

.sumo-audio-text {
    color: #7c60d9;
}

.sumo-video-text {
    color: #c62e2d;
}

/* ICONS */

.sumo-icon-normal {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: var(--color2);
}

.sumo-icon-normal:hover {
    fill: #fff;
}

.sumo-icon-small {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.sumo-rounded-icon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sumo-square-icon,
.sumo-square-toggle {
    width: 42px;
    height: 42px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sumo-square-icon svg {
    fill: var(--color2);
}

.sumo-square-icon.stroke svg {
    fill: none;
    stroke: var(--color2);
}

.sumo-square-highlighted {
    box-shadow: inset 0 -4px var(--color1)
}

.sumo-square-highlighted-right {
    box-shadow: inset -4px 0px var(--color1)
}

.sumo-square-icon.sumo-square-highlighted svg {
    fill: #fff;
}

div[role="button"] svg {
    pointer-events: none;
}

/* COLLAPSIBLE */
.sumo-collapsible {}

.sumo-collapsible .header {
    position: relative;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    height: 23px;
    padding: 0 5px;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    border-bottom: 1px solid var(--color3);
    margin: 0px 0px 10px 0px;
    cursor: pointer;
}

.sumo-collapsible .header label {
    color: var(--color1);
    pointer-events: none;
}

.sumo-collapsible .header:after {
    content: "";
    width: 5px;
    height: 5px;
    border: solid var(--color2);
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 6px;
    transition: 0.1s transform ease-out;
}

.sumo-collapsible.open {
    padding-bottom: 15px;
}

.sumo-collapsible.open .header:after {
    transform: rotate(45deg);
}

.sumo-collapsible.closed .header:after {
    transform: rotate(-45deg);
}

.sumo-collapsible .content {}

.sumo-collapsible .sumo-checkbox p {
    max-width: 200px;
}


/* BUTTONS */

.sumo-btn {
    height: 30px;
    font-family: "Quicksand", arial;
    padding: 8px 20px;
    font-weight: bold;
    color: #fff;
    font-size: 11px;
    border: 1px solid;
    border-radius: 100px;
    min-width: 90px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    outline: none;
    cursor: pointer;
    transition: all ease-in-out 0.1s;
}

.sumo-btn-paint {
    background-color: var(--color6);
    border-color: var(--color6);
}

.sumo-btn-tunes {
    background-color: #e5ab42;
    border-color: #e5ab42;
}

.sumo-btn-3d {
    background-color: var(--color1);
    border-color: var(--color1);
}

.sumo-btn-code {
    background-color: #22cc71;
    border-color: #22cc71;
}

.sumo-btn-photo {
    background-color: #30b7a7;
    border-color: #30b7a7;
}

.sumo-btn-audio {
    background-color: #7c60d9;
    border-color: #7c60d9;
}

.sumo-btn-video {
    background-color: #c62e2d;
    border-color: #c62e2d;
}

.sumo-btn-colored:hover {
    filter: brightness(1.1);
}

.sumo-btn-transparent {
    color: var(--color2);
    background-color: var(--color4);
    border-color: var(--color2);
}

.sumo-btn-transparent:hover {
    background: var(--color2);
    border-color: var(--color2);
    color: #222527;
}

/* INPUTS */

/* INPUT TEXT */
.sumo-input {
    width: auto;
    display: inline-block;
    background: #222527;
    color: var(--color2);
    border: none;
    border-radius: 100px;
    height: 24px;
    padding: 0 10px;
    outline: none;
    font-size: 11px;
}

#sumo-modal p,
#sumo-modal label,
#sumo-modal div,
#sumo-modal span,
#sumo-modal select {
    font-size: 11px;
}

@media all and (max-width: 720px) {

    #sumo-modal .sumo-input,
    #sumo-modal label,
    #sumo-modal select {
        font-size: 16px;
    }
}

.sumo-input::-webkit-input-placeholder {
    /* Edge */
    opacity: 0.4;
}

.sumo-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    opacity: 0.4;
}

.sumo-input::placeholder {
    opacity: 0.4;
}

.sumo-input[type='number']::-webkit-outer-spin-button,
.sumo-input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -webkit-appearance: none;
    appearance: none;

}

/* SELECT */

.sumo-range input,
.sumo-select select {
    background: #222527;
    border: none;
    outline: none;
    color: var(--color2);
    font-size: 11px;
    padding: 0 10px;
    height: 24px;
    border-radius: 100px;
    -webkit-appearance: none;
    cursor: pointer;
    width: 100%;
}

.sumo-range {
    max-width: 48px;
}

.sumo-range input {
    border-radius: 100px 0 0 100px;
    padding: 0 15px 0 7px;
    text-align: right;
}

.sumo-range:after {
    content: attr(data-content);
    position: absolute;
    color: #666;
    background: #222627;
    top: 0;
    bottom: 0;
    margin: auto;
    height: max-content;
    right: 1px;
}

.sumo-select {
    min-width: 55px;
}

.sumo-range,
.sumo-select {
    position: relative;
    display: inline-block;
}

.sumo-select,
.sumo-dropdown-slider {
    display: flex;
    align-items: center;
}

.select-toggle:after,
.range-toggle:after,
.sumo-select:after {
    content: "";
    transform: rotate(45deg);
    width: 5px;
    height: 5px;
    border: solid var(--color2);
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 6px;
}

/* CHECKBOX */
.sumo-checkbox {
    position: relative;
    width: 24px;
    height: 24px;
    width: max-content;
}

.sumo-checkbox label {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: flex;
    align-items: center;
    width: max-content;
    height: 100%;
}

.sumo-checkbox.checkbox-right label {
    padding: 0;
}

.sumo-checkbox.checkbox-right input,
.sumo-checkbox.checkbox-right .checkmark {
    left: initial;
    right: -24px;
}

/* Hide the browser's default checkbox */
.sumo-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
}

.sumo-checkbox p {
    margin: 0;
    font-size: 11px;
    color: var(--color2);
    display: inline-block;
}

.checkmark:after {
    left: 9px;
    top: 5px;
    width: 4px;
    height: 9px;
    border: solid var(--color2);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    background: #222527;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    cursor: pointer;
    pointer-events: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.sumo-checkbox input:checked~.checkmark:after {
    display: block;
}

.sumo-radio {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: #222527;
}

.sumo-radio label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.sumo-radio input {
    width: 12px;
    height: 12px;
    -webkit-appearance: none;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    margin: 0;
    pointer-events: none
}

.sumo-radio input:checked {
    background: var(--color2);
}

/* HOVERS */

.sumo-btn-colored:hover,
.sumo-square-icon:hover,
.sumo-rounded-icon:hover {
    filter: brightness(1.1);
}

.sumo-square-toggle:hover {
    filter: none;
}

/* Extra styles */
.modal-content .modal-content-highlighted {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.modal-content a {
    color: var(--color6);
}

.sumo-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-align-center {
    display: flex;
    align-items: center;
    position: relative;
}

input.sumo-input.adjustmentInput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.dropdown-slider-wrapper,
.dropdown-options-wrapper {
    display: flex;
    position: relative;
}

.spinner h1 {
    position: fixed;
    top: 42px;
    left: 0;
    right: 280px;
    bottom: 0;
    z-index: 999999999;
    font-size: 22px;
    color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
}

.App.tablet .spinner h1 {
    top: 42px;
    left: 42px;
    right: 0px;
    bottom: 42px;
}

.App.mobile .spinner h1 {
    top: 42px;
    left: 0px;
    right: 0px;
    bottom: 42px;
}

.fade-animation {
    animation: fade 0.8s infinite linear;
    transform-origin: center center !important;
}

@keyframes fade {
    0% {
        opacity: 1.0
    }

    62% {
        opacity: 0.62
    }

    100% {
        opacity: 1.0
    }
}

.autosaving-indicator {
    position: absolute;
    top: 13px;
    right: 13px;
    z-index: 99999;
    opacity: .5;
}

.autosaving-indicator img {
    width: 16px;
    height: 16px;
}

.sumo-navbar .sumo-menu .sumo-header-navbar svg rect {
    transform-origin: center;
}

.sumo-navbar .sumo-menu .sumo-header-navbar.working svg rect {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}



.library-updated-animation {
    animation: bounce 0.3s 6 linear;
    transform-origin: center center !important;
}

@keyframes bounce {
    0% {
        transform: translate(0, 0)
    }

    62% {
        transform: translate(0, -5px)
    }

    100% {
        transform: translate(0, 0)
    }
}

span.MuiTypography-body1 {
    font-family: "Quicksand", sans-serif;
    font-size: 13px;
    font-weight: bold;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    border-radius: 30px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--color3);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--color1);
    border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-col {
    flex-direction: column;
}

.video-export-result a {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
}

.pending-renders-wrapper {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background: #111;
    z-index: 9999999999;
    padding: 1.5rem 2rem;
    border-radius: 1rem;
}

.spinner-rotation {
    width: 1rem;
    height: 1rem;
    animation: rotate 0.9s infinite linear;
    transform-origin: center center !important;
}

.close-pending-renders {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.pending-renders-wrapper .video-link {
    color: red;
    font-weight: bold;
    text-decoration: underline;
}

.video-export-ads {
    margin-top: 1rem;
}

.video-export-ads p {
    width: 100%;
    margin: 1rem 0 !important;
    text-align: center;
}

.video-export-ads button {
    background: var(--color1);
    color: #fff;
    font-weight: bold;
}
#render-engine-playback-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99;
    background: #080808;
    height: 3rem;
}

#render-engine-playback-buttons button {
    /* background: var(--color1); */
    background: none;
    color: #fff;
    font-weight: bold;
    display: flex;
    height: 2rem;
    width: 2rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none;
}

#render-engine-playback-buttons button:hover {
    opacity: 0.8;
}

#render-engine-progress-bar {
    z-index: -1;
    height: 2rem;
    border-bottom: 1px solid red;
    position: absolute;
    bottom: 0;
    left: 0;
}

button.single-shortcut {
    padding: 5px 8px;
    border-radius: 9px;
    border: 1px solid var(--color3);
    font-weight: bold;
    background: none;
    color: var(--color2);
    font-family: Quicksand;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
}

button.single-shortcut:hover {
    border-color: var(--color1);
    color: var(--color7)
}
#video-player-close-btn {
    position: fixed;
    top: 5px;
    right: 5px;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    font-size: 1.5rem;
}

#render-video-wrapper {
    background: #000
}